<template>
  <GeneralDialogTemplate no-padding>
    <template slot="header">
      <span class="font-weight-medium">Kategooria lisamine</span>
    </template>
    <v-form
      ref="form"
      v-model="valid"
      submit="onSaveCategory"
    >
      <v-row no-gutters>
        <v-col class="pa-4">
          <v-text-field
            v-model.trim="formData.category"
            outlined
            dense
            label="Kategooria"
            required
            :rules="[v => !!v]"
          />
          <v-select
            :value="formData.branch"
            label="Koondis"
            :items="branches"
            item-text="name"
            item-value="value"
            dense
            disabled
            hide-details
            outlined
            :rules="[v => !!v]"
            required
          />
        </v-col>
      </v-row>
    </v-form>
    <template #footer>
      <v-spacer /><v-btn
        text
        color="primary"
        @click="closeDialog"
      >
        Sulge
      </v-btn>
      <v-btn
        color="primary"
        type="submit"
        @click="onSaveCategory"
      >
        Salvesta
      </v-btn>
    </template>
  </GeneralDialogTemplate>
</template>
<script>
import GeneralDialogTemplate from '@/components/templates/GeneralDialogTemplate.vue';
import {
  toRefs, reactive, computed, ref,
} from '@vue/composition-api';
import dialogModule from '@/store/modules/dialog';
import libaryModule from '@/store/modules/library';
import { orgBranches } from '@/helpers/common-data';

export default {
  components: { GeneralDialogTemplate },
  setup() {
    const form = ref(null);
    const {
      saveCategory,
    } = libaryModule();
    const state = reactive({
      valid: true,
      formData: {
        category: '',
        branch: '',
      },
    });
    const branches = computed(() => orgBranches.filter((b) => b.value !== 'vilkogu'));
    const { dialogPayload, closeDialog } = dialogModule();
    state.formData = { ...state.formData, ...dialogPayload.value.category };
    const onSaveCategory = async () => {
      await form.value.validate();
      if (!state.valid) return;
      const category = await saveCategory(state.formData);
      dialogPayload.value.action(category.id);
      closeDialog();
    };
    return {
      ...toRefs(state),
      form,
      branches,
      closeDialog,
      onSaveCategory,
    };
  },
};
</script>
